import { ChevronDown } from "baseui/icon";
import React, { FC } from "react";

import { WorkDetailsModel } from "../../../../../../factories/work/model/work-details.model";
import { StatusesDropdown } from "../../../../../works/dropdown/StatusesDropdown";
import { getStatusColor } from "../../../../../works/dropdown/utils";
import { WorkTaskModel } from "../../../../../../factories/work/model/work-task.model";

type Props = {
  work?: WorkDetailsModel;
  workTask?: WorkTaskModel;
  workTypeId?: string;
};
export const WorkStatus: FC<Props> = ({ work, workTask, workTypeId }) => {
  const getStatusName = () => {
    if (workTask) {
      return workTask?.subStatus
        ? workTask?.subStatus?.subStatus
        : workTask?.status?.name || "No status";
    } else {
      return work?.jobSubStatus
        ? work?.jobSubStatus?.subStatus
        : work?.jobStatus?.name || "No status";
    }
  };

  return (
    <div>
      {(work?.jobStatus || workTask) && (
        <StatusesDropdown
          workTask={workTask}
          workTypeId={work?.serviceId || workTypeId || ""}
          workId={work?.id || ""}
          positionType={"right"}
          triggerElement={
            <div
              className={
                "flex w-fit cursor-pointer items-center gap-1 whitespace-nowrap rounded-[8px] border border-[#EAEDF3A3] bg-[#F8F9FB] px-1 py-0.5 text-sm font-semibold text-gray-800"
              }>
              <div
                className={"flex h-[10px] w-[10px] rounded-full"}
                style={{
                  backgroundColor: workTask
                    ? getStatusColor(workTask?.status?.name || "")
                    : getStatusColor(work?.jobStatus?.name || ""),
                }}
              />
              <div className={"max-w-[140px] truncate"}>{getStatusName()}</div>
              <ChevronDown size={16} />
            </div>
          }
        />
      )}
    </div>
  );
};
