import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Datepicker } from "baseui/datepicker";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/button/Button";
import CustomCurrencyInput from "../../../components/CustomCurrencyInput";
import { LottieLoading } from "../../../components/graphics/LottieLoading";
import CustomSelect from "../../../components/select/Select";
import {
  getInitialsFromFullName,
  timeFormatter,
} from "../../../constants/constants";
import { useAppSelector } from "../../../redux/redux";
import {
  IEditInvoice,
  useGetInvoiceByIdQuery,
  useGetInvoiceTermsQuery,
  useGetPaymentsRecievedQuery,
  useGetServicesQuery,
  useGetTaxRateQuery,
  useUpdateInvoiceMutation,
} from "../../../services/BillingService";
import {
  useGetUsersQuery,
  useGetWorksByCustomerIdMutation,
} from "../../../slices/UserSlice";
import { getLinkedFirm } from "../../../sessionStorage/sessionStorage";
import { FormSection } from "../../../support/FormSection";
import { SelectOptionType } from "../../../types/types";
import { transformDateToUTC } from "../../../utils/transformDate";
import { BillableTime } from "./BillableTime";
import { InvoiceTime } from "../../time/InvoiceTime";
import { Item } from "./types";
import { IoTimeOutline } from "react-icons/io5";
import { ViewWork } from "./ViewWork";
import ClockSvg from "../../../components/svg/clock-icon.svg";

const discountTypes = {
  "%": "89EDC863-9055-479B-AD08-554C0418A59F",
  $: "771B703A-5E94-4271-988A-206C0B48F7A8",
};

const EditInvoice = ({
  setView,
}: {
  setView: React.Dispatch<React.SetStateAction<"details" | "edit">>;
}) => {
  const { user } = useAppSelector((state) => state.appReducer);

  const { id } = useParams<{ id: string }>();

  const [updateInvoice, { isSuccess: isSuccessUpdate }] =
    useUpdateInvoiceMutation();
  const { data: terms, isLoading: isLoadingTerms } = useGetInvoiceTermsQuery();
  const { data: invoice, isLoading: isLoadingInvoice } = useGetInvoiceByIdQuery(
    `${id}`,
  );
  const { data: paymentsRecieved, isLoading: isLoadingPaymentsRecieved } =
    useGetPaymentsRecievedQuery(`${id}`);

  const { data: services, isLoading: isLoadingServices } = useGetServicesQuery(
    getLinkedFirm()?.orgId,
  );
  const { data: taxRate, isLoading: isLoadingTaxRate } = useGetTaxRateQuery(
    getLinkedFirm()?.orgId,
  );
  const { data: users, isLoading: isLoadingUsers } = useGetUsersQuery(
    getLinkedFirm()?.orgId,
  );
  const [getWorks, { data: allItems, isLoading }] =
    useGetWorksByCustomerIdMutation();

  const navigate = useNavigate();

  const [contacts, setContacts] = useState<SelectOptionType[]>([]);
  const [invoiceCustomers, setInvoiceCustomers] = useState<SelectOptionType[]>(
    [],
  );
  const [isShowAddClient, setIsShowAddClient] = useState(false);

  const [currentContact, setCurrentContact] = useState<SelectOptionType | null>(
    null,
  );
  const [currentTimeIds, setCurrentTimeIds] = useState<string[]>([]);

  const [data, setData] = useState<Item[]>([
    {
      service: services?.[0]?.id || "",
      description: services?.[0]?.description || "",
      qty: 1,
      rate: services?.[0]?.rate?.toString() || "",
      amount: services?.[0]?.rate || 0,
      invoiceType: "invoiceItem",
      workTypeId: "",
      assignedUserId: "",
      workId: null,
      duration: 0,
    },
  ]);
  const [isIncludeTax, setIsIncludeTax] = useState(
    invoice?.isIncluded || false,
  );
  const [invoiceDate, setInvoiceDate] = useState(new Date());
  const [term, setTerm] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [dueDate, setDueDate] = useState(new Date());
  const [discount, setDiscount] = useState("");
  const [discountType, setDiscountType] = useState<"$" | "%">("%");
  const [taxRateValue, setTaxRateValue] = useState(0);
  const [notes, setNotes] = useState("");
  const [showAll, setShowAll] = useState(false);
  const [collapsedItems, setCollapsedItems] = useState<string[]>([]);
  const [billedWorks, setBilledWorks] = useState<InvoiceTime[]>([]);
  const [viewWork, setViewWork] = useState(false);
  const [workItem, setWorkItem] = useState<Item>();

  const subtotal = useMemo(() => {
    let value = 0;
    data.forEach((item) => (value += item.amount));
    return value;
  }, [data, isIncludeTax]);

  const discountValue = useMemo(() => {
    if (discountType === "$") {
      return +discount || 0;
    }
    if (discountType === "%") {
      return (subtotal * (+discount || 0)) / 100;
    }
    return 0;
  }, [discount, discountType, subtotal]);

  const taxValue = useMemo(() => {
    if (isIncludeTax) {
      const tax: number = (taxRateValue || 0) / 100 + 1;
      const subtotalWithoutTax = data.reduce(
        (acc, item) => acc + item.amount / tax,
        0,
      );
      return (
        (((subtotalWithoutTax || 0) - (discountValue || 0)) *
          (taxRateValue || 0)) /
        100
      );
    }
    return (
      (((subtotal || 0) - (discountValue || 0)) * (taxRateValue || 0)) / 100
    );
  }, [subtotal, discountValue, taxRateValue, isIncludeTax]);

  const total = useMemo(() => {
    return isIncludeTax
      ? subtotal - discountValue
      : subtotal - discountValue + taxValue;
  }, [subtotal, taxValue, discountValue, isIncludeTax]);

  const balanceDue = useMemo(() => {
    return total - (paymentsRecieved || 0);
  }, [paymentsRecieved, total]);

  const addWorkItems = (items: InvoiceTime[]) => {
    setData((prev) => {
      const newBilledWorks: InvoiceTime[] = [];

      items?.forEach((item) => {
        if (!prev.find((i) => i?.id === item?.id)) {
          newBilledWorks.push(item);
        }
      });
      return [
        ...prev,
        ...(newBilledWorks?.map((i) => ({
          service: services?.[0]?.id || "",
          description: i?.work?.description || "",
          qty: +totalRate(timeFormatter(i.duration)).toFixed(2),
          rate: i.assignedUser?.hourlyRate.toString(),
          isWork: true,
          id: i.id,
          workType: i.work.service.name,
          workName: i.work.name,
          dueDate: i.work.dueDate,
          assignedUser: `${i.assignedUser?.userProfile.firstName} ${i.assignedUser?.userProfile.lastName}`,
          invoiceType: "billedItem",
          duration: i.duration,
          assignedUserId: i.assignedUserId,
          workId: i.work?.id || i.id,
          workTypeId: i.work.service.id,
          amount:
            totalRate(timeFormatter(i.duration)) *
            (i.assignedUser?.hourlyRate || 0),
        })) as Item[]),
      ];
    });
    setBilledWorks(items);
    setShowAll(false);
  };
  const onClickAddItem = () => {
    setData(() => [
      ...data,
      {
        service: services?.[0]?.id || "",
        description: services?.[0]?.description || "",
        qty: 1,
        rate: services?.[0]?.rate?.toString() || "",
        amount: services?.[0]?.rate || 0,
        invoiceType: "invoiceItem",
        workTypeId: "",
        assignedUserId: "",
        workId: null,
        duration: 0,
      },
    ]);
  };

  const onClickDeleteItem = (id: string, index: number) => {
    if (data.length > 1) {
      setData(() => data.filter((_, i) => i !== index));
      setBilledWorks(billedWorks.filter((i) => i.id !== id));
    }
  };

  useEffect(() => {
    if (terms && terms?.length > 0 && term) {
      const name = terms?.find((item) => item.id === term)?.termName || "";
      if (name === "Due on Receipt") {
        setDueDate(invoiceDate);
      }
      if (name === "Net 60") {
        setDueDate(moment(invoiceDate).add(60, "days").toDate());
      }
      if (name === "Net 30") {
        setDueDate(moment(invoiceDate).add(30, "days").toDate());
      }
      if (name === "Net 15") {
        setDueDate(moment(invoiceDate).add(15, "days").toDate());
      }
      if (name === "Other") {
      }
    }
  }, [term]);

  useEffect(() => {
    getWorks({ customerId: invoice?.customerId || "" });
  }, [user]);

  useEffect(() => {
    if (isSuccessUpdate) {
      setView("details");
    }
  }, [isSuccessUpdate]);

  function findMissingElements(array1: string[], array2: string[]): string[] {
    return array1?.filter((item) => !array2?.includes(item));
  }

  const update = () => {
    if (currentContact && data.length > 0) {
      const body: IEditInvoice = {
        isIncluded: isIncludeTax,
        id: invoice?.id || "",
        notes: notes || "",
        assignedToUserId: selectedUser ? selectedUser : null,
        orgId: getLinkedFirm()?.orgId,
        createdByUserId: user.id,
        customerId: currentContact?.value || "",
        invoiceNo: invoice?.invoiceNo || 0,
        tax: taxRateValue || 0,
        termId: term,
        balance: +(balanceDue || 0).toFixed(2),
        discount: +discount,
        paymentRecieved: +(paymentsRecieved || 0).toFixed(2),
        total: +total.toFixed(2),
        invoiceDate: moment(invoiceDate).startOf("day").toISOString(),
        dueDate: moment(dueDate).startOf("day").toISOString(),
        discountTypeId: discountTypes[discountType].toLowerCase(),
        invoiceCustomers: findMissingElements(
          invoiceCustomers?.map((item) => item?.value),
          invoice?.invoiceCustomers?.map((item) => item?.customerId) || [],
        )?.map((item) => ({ customerId: item })),
        invoiceClientsIds: findMissingElements(
          invoice?.invoiceCustomers?.map((item) => item?.customerId) || [],
          invoiceCustomers?.map((item) => item?.value),
        ),
        invoiceStatusId: invoice?.invoiceStatusId || null,
        invoiceItem: data.map((item) => ({
          id: item.id,
          description: item.description,
          billingServiceId: item.service,
          amount: item.amount,
          rate: +item.rate || 0,
          quantity: item.qty,
        })),
      };

      updateInvoice(body);
    }
  };

  useEffect(() => {
    if (invoice && terms) {
      setTerm(invoice?.termId || "");
    }
  }, [terms, invoice]);

  useEffect(() => {
    if (invoice && users) {
      setSelectedUser(invoice?.assignedToUserId || "");
    }
  }, [users, invoice]);

  useEffect(() => {
    if (invoice) {
      setNotes(invoice?.notes);
      setInvoiceDate(new Date(invoice?.invoiceDate));
      setDueDate(new Date(invoice?.dueDate));
      setDiscount(invoice?.discount.toString() || "");
      setTaxRateValue(invoice?.tax || 0);
      setIsIncludeTax(invoice?.isIncluded || false);
      if (invoice?.discountTypeId) {
        setDiscountType(
          invoice?.discountTypeId === discountTypes.$.toLowerCase() ? "$" : "%",
        );
      }
      setCurrentContact({
        value: invoice?.customerId || "",
        label: invoice?.customer?.name || "",
        avatar: getInitialsFromFullName(invoice?.customer?.name || ""),
      });
      setInvoiceCustomers(
        invoice?.invoiceCustomers?.map((customer) => ({
          value: customer?.customerId || "",
          label: customer?.customers?.name || "",
          avatar: getInitialsFromFullName(customer?.customers?.name || ""),
        })),
      );
      setIsShowAddClient(!!invoice?.invoiceCustomers?.length);
    }
  }, [invoice]);

  useEffect(() => {
    if (invoice) {
      setData(
        invoice?.invoiceItem
          .slice()
          .sort((a, b) => {
            if (moment(a?.createdAt).unix() > moment(b?.createdAt).unix()) {
              return 1;
            }
            if (moment(a?.createdAt).unix() < moment(b?.createdAt).unix()) {
              return -1;
            }

            return 0;
          })
          .map((item) => ({
            service: item.billingServiceId,
            description: item.description,
            qty: item.quantity,
            rate: item.rate.toString(),
            amount: item.amount,
            id: item.id,
            workTypeId: item.workTypeId,
            assignedUserId: item.assignedUserId,
            invoiceType: item.invoiceType,
            workId: item.workId,
            duration: item.duration,
          })),
      );
    }
  }, [invoice]);

  const onClickIncludeTax = (value: boolean) => {
    const tax: number = (taxRateValue || 0) / 100 + 1;
    setIsIncludeTax(value);
    if (value) {
      setData(
        data?.map((item) => ({
          ...item,
          rate: (+item?.rate * tax).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          amount: +item?.rate * item.qty * tax,
        })),
      );
    } else {
      setData(
        data?.map((item) => ({
          ...item,
          rate: (+item?.rate / tax).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          amount: (+item?.rate * item.qty) / tax,
        })),
      );
    }
  };
  const totalRate = (value: string) => {
    const hours = Number(value.split("h").slice(0, -1));
    const minutes = Number(value?.split("h")?.pop()?.replace("m", "")) / 60;
    return hours + minutes;
  };
  if (
    isLoadingTerms ||
    isLoadingServices ||
    isLoadingTaxRate ||
    isLoadingInvoice ||
    isLoadingUsers ||
    isLoadingPaymentsRecieved
  ) {
    return <LottieLoading />;
  }

  if (
    services?.length === 0 ||
    taxRate?.length === 0 ||
    !services ||
    !taxRate
  ) {
    return (
      <div className={"text-center text-lg font-semibold"}>
        <div className={"text-center text-gray-800"}>
          Check your services and tax rate in settings{" "}
        </div>
        <div
          className={"link-primary cursor-pointer text-center hover:underline"}
          onClick={() => navigate("/settings/billing")}>
          Go to settings
        </div>
      </div>
    );
  }

  const tableRows = data.map((item, index) => {
    return (
      <tr
        key={index}
        className={"border-b-[1px] border-gray-300 hover:bg-gray-100"}>
        <td
          className={
            "border-r-[1px] border-gray-300 bg-transparent px-4 py-2 text-sm font-semibold text-gray-800"
          }>
          <select
            defaultValue={"1"}
            className={"select select-bordered"}
            value={item.service}
            onChange={(e) =>
              setData((prev) =>
                prev.map((invoice, i) =>
                  i === index
                    ? {
                        ...item,
                        service: e.target.value,
                        description:
                          services?.find((s) => s.id === e.target.value)
                            ?.description || "",
                        rate:
                          item.invoiceType === "billedItem"
                            ? item.rate
                            : (
                                services?.find((s) => s.id === e.target.value)
                                  ?.rate || 0
                              ).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }),
                        amount:
                          item.invoiceType === "billedItem"
                            ? item.amount
                            : item.qty *
                              (services?.find((s) => s.id === e.target.value)
                                ?.rate || 0),
                      }
                    : invoice,
                ),
              )
            }>
            {services?.map((type) => (
              <option key={type.id} value={type.id}>
                {type.name}
              </option>
            ))}
          </select>
        </td>
        <td
          className={
            "border-r-[1px] border-gray-300 bg-transparent px-4 py-2 text-sm font-semibold text-gray-800"
          }>
          <input
            type={"text"}
            className={"input input-bordered"}
            value={item.description}
            onChange={(e) =>
              setData((prev) =>
                prev.map((invoice, i) =>
                  i === index
                    ? { ...item, description: e.target.value }
                    : invoice,
                ),
              )
            }
          />
        </td>
        <td
          className={
            "border-r-[1px] border-gray-300 bg-transparent px-4 py-2 text-sm font-semibold text-gray-800"
          }>
          <input
            type={"text"}
            className={"input input-bordered"}
            value={item.qty}
            onChange={(e) =>
              setData((prev) =>
                prev.map((invoice, i) =>
                  i === index
                    ? {
                        ...item,
                        qty: new RegExp("^[0-9]*$").test(e.target.value)
                          ? Number(e.target.value)
                          : item.qty,
                        amount: Number(e.target.value) * +item.rate,
                      }
                    : invoice,
                ),
              )
            }
          />
        </td>
        <td
          className={
            "border-r-[1px] border-gray-300 bg-transparent px-4 py-2 text-sm font-semibold text-gray-800"
          }>
          <CustomCurrencyInput
            value={item.rate}
            setValue={(value) =>
              setData((prev) =>
                prev.map((invoice, i) =>
                  i === index
                    ? {
                        ...item,
                        rate: value,
                        amount: (+value || 0) * item.qty,
                      }
                    : invoice,
                ),
              )
            }
          />
        </td>
        <td
          className={
            "border-r-[1px] border-gray-300 bg-transparent px-4 py-2 text-sm font-semibold text-gray-800"
          }>
          $
          {item.amount.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </td>
        {data?.length > 1 ? (
          <td
            className={"bg-transparent px-4 py-2 text-lg text-red-600"}
            onClick={() => onClickDeleteItem(item?.id || "", index)}>
            <FontAwesomeIcon icon={faTrashCan} className={"cursor-pointer"} />
          </td>
        ) : (
          <td className={"bg-transparent px-4 py-2 text-lg text-gray-300"}>
            <FontAwesomeIcon icon={faTrashCan} />
          </td>
        )}
        <td className={"bg-transparent px-4 py-2 text-lg text-gray-300"}>
          {item.invoiceType === "billedItem" && (
            <>
              <IoTimeOutline
                className={"text-purple h-6 w-6 cursor-pointer"}
                onClick={() => {
                  setViewWork(true);
                  setWorkItem(item);
                }}
              />
              {viewWork && (
                <ViewWork
                  assignedUser={workItem?.assignedUser}
                  qty={workItem?.qty}
                  workType={workItem?.workType}
                  workName={workItem?.workName}
                  billedWorks={invoice?.invoiceItem}
                  billedWork={workItem}
                  isActive={viewWork}
                  setCollapsedItems={setCollapsedItems}
                  collapsedItems={collapsedItems}
                  setViewWork={setViewWork}
                  totalRate={totalRate}
                />
              )}
            </>
          )}
        </td>
      </tr>
    );
  });

  return (
    <>
      <FormSection
        name={"Edit invoice"}
        classForTitle={"p-0"}
        extraCx={"shadow-box"}>
        <div className={"flex items-start justify-between"}>
          <div className={"flex flex-col gap-6"}>
            <div className={"w-fit"}>
              <div className={"mb-2 text-base uppercase text-gray-400"}>
                Client
              </div>
              <div className={"flex items-center gap-4"}>
                <div className={"w-[300px]"}>
                  <CustomSelect
                    placeholder={"Search client"}
                    value={currentContact}
                    options={contacts}
                    onChange={(newValue) =>
                      setCurrentContact({ ...(newValue as SelectOptionType) })
                    }
                  />
                </div>
                {currentContact && (
                  <>
                    {!isShowAddClient ? (
                      <div
                        onClick={() => setIsShowAddClient(true)}
                        className={"link link-primary text-base font-semibold"}>
                        Add client
                      </div>
                    ) : (
                      <div className={"min-w-[200px]"}>
                        <CustomSelect
                          placeholder={"Search client"}
                          isMulti
                          value={invoiceCustomers}
                          options={contacts}
                          onChange={(newValue) => {
                            setInvoiceCustomers(newValue as SelectOptionType[]);
                          }}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className={"flex items-center gap-6"}>
              <div>
                <div className={"mb-2 text-base uppercase text-gray-400"}>
                  Invoice date
                </div>
                <div className={"text-base text-gray-800"}>
                  <Datepicker
                    value={invoiceDate}
                    overrides={{
                      Input: {
                        props: {
                          overrides: {
                            Input: {
                              style: () => ({
                                backgroundColor: "#FFFFFF",
                              }),
                            },
                            Root: {
                              style: () => ({
                                borderTopWidth: "1px",
                                borderRightWidth: "1px",
                                borderBottomWidth: "1px",
                                borderLeftWidth: "1px",
                                borderTopColor: "#D4D6D9",
                                borderRightColor: "#D4D6D9",
                                borderBottomColor: "#D4D6D9",
                                borderLeftColor: "#D4D6D9",
                              }),
                            },
                          },
                        },
                      },
                    }}
                    onChange={({ date }) => {
                      if (date instanceof Date) {
                        setInvoiceDate(transformDateToUTC(date));
                      }
                    }}
                  />
                </div>
              </div>
              <div>
                <div className={"mb-2 text-base uppercase text-gray-400"}>
                  Terms
                </div>
                <div className={"text-base text-gray-800"}>
                  <select
                    className={"select select-bordered"}
                    value={term}
                    onChange={(e) => setTerm(e.target.value)}>
                    {terms?.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.termName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div>
                <div className={"mb-2 text-base uppercase text-gray-400"}>
                  Due date
                </div>
                <div className={"text-base text-gray-800"}>
                  <Datepicker
                    value={dueDate}
                    overrides={{
                      Input: {
                        props: {
                          overrides: {
                            Input: {
                              style: () => ({
                                backgroundColor: "#FFFFFF",
                              }),
                            },
                            Root: {
                              style: () => ({
                                borderTopWidth: "1px",
                                borderRightWidth: "1px",
                                borderBottomWidth: "1px",
                                borderLeftWidth: "1px",
                                borderTopColor: "#D4D6D9",
                                borderRightColor: "#D4D6D9",
                                borderBottomColor: "#D4D6D9",
                                borderLeftColor: "#D4D6D9",
                              }),
                            },
                          },
                        },
                      },
                    }}
                    onChange={({ date }) => {
                      if (date instanceof Date) {
                        setDueDate(transformDateToUTC(date));
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className={"mb-2 text-base uppercase text-gray-400"}>
                Assignee
              </div>
              <div className={"text-base text-gray-800"}>
                <select
                  className={"select select-bordered max-w-[200px]"}
                  value={selectedUser}
                  onChange={(e) => setSelectedUser(e.target.value)}>
                  {!selectedUser && <option value={""}></option>}
                  {users?.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user?.userProfile &&
                        `${user?.userProfile?.firstName} ${user?.userProfile?.middleName} ${user?.userProfile?.lastName}`}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div>
            <div className={"mb-2 text-base uppercase text-gray-400"}>
              Invoice No
            </div>
            <div
              className={
                "input input-bordered flex items-center justify-center"
              }>
              {invoice?.invoiceNo}
            </div>
          </div>
        </div>
      </FormSection>
      <FormSection name={""} classForTitle={"p-0"} extraCx={"shadow-box"}>
        <div className={"flex items-center justify-end gap-4"}>
          <div className={"text-base font-semibold text-gray-500"}>
            Include Tax
          </div>
          <input
            type="checkbox"
            className={"toggle toggle-primary"}
            checked={isIncludeTax}
            onChange={(e) => onClickIncludeTax(e.target.checked)}
          />
        </div>
        <table className={"w-full"}>
          <thead className={"border-b-[1px] border-gray-300 bg-gray-200"}>
            <tr>
              <th className={"py-4 pl-4 pr-8 text-gray-800 last:pr-4"}>
                <div
                  className={
                    "flex whitespace-nowrap text-sm font-bold normal-case "
                  }>
                  Service
                </div>
              </th>
              <th className={"py-4 pl-4 pr-8 text-gray-800 last:pr-4"}>
                <div
                  className={
                    "flex whitespace-nowrap text-sm font-bold normal-case "
                  }>
                  Description
                </div>
              </th>
              <th className={"py-4 pl-4 pr-8 text-gray-800 last:pr-4"}>
                <div
                  className={
                    "flex whitespace-nowrap text-sm font-bold normal-case "
                  }>
                  Qty
                </div>
              </th>
              <th className={"py-4 pl-4 pr-8 text-gray-800 last:pr-4"}>
                <div
                  className={
                    "flex whitespace-nowrap text-sm font-bold normal-case "
                  }>
                  Rate
                </div>
              </th>
              <th className={"py-4 pl-4 pr-8 text-gray-800 last:pr-4"}>
                <div
                  className={
                    "flex whitespace-nowrap text-sm font-bold normal-case "
                  }>
                  Amount
                </div>
              </th>
              <th className={"py-4 pl-4 pr-8 text-gray-500 last:pr-4"}></th>
              <th
                className={
                  "items-center py-4 pl-4 pr-8 text-gray-500 last:pr-4"
                }>
                <img
                  src={ClockSvg}
                  alt={"Time"}
                  className={"h-[24px] w-[24px]"}
                />
              </th>
            </tr>
          </thead>
          <tbody className={"text-[14px]"}>{tableRows}</tbody>
        </table>
        {!allItems?.length && showAll && (
          <div className={"font-semibold text-error"}>
            There are no pending time entries
          </div>
        )}
        <div className={"flex items-start justify-between"}>
          <div>
            <div
              className={
                "link-primary my-2 cursor-pointer pl-4 text-base font-semibold hover:underline"
              }
              onClick={onClickAddItem}>
              + Add line item
            </div>
            <div
              className={
                "link-primary my-2 cursor-pointer pl-4 text-base font-semibold hover:underline"
              }
              onClick={() => setShowAll(true)}>
              + Billable time
            </div>
            <div>
              <div
                className={"mb-2 mt-20 text-base font-semibold text-gray-800"}>
                Notes
              </div>
              <textarea
                className={"textarea textarea-bordered min-w-[300px]"}
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </div>
          </div>
          <div className={"flex min-w-[300px] max-w-[500px] flex-col"}>
            <div
              className={
                "mb-2 flex w-full justify-between text-base font-semibold text-gray-800"
              }>
              <span className={"w-[200px]"}>Subtotal:</span> $
              {(subtotal || 0).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
            <div
              className={"mb-2 flex w-full items-center justify-between gap-2"}>
              <div className={"text-base font-semibold text-gray-800"}>
                <span className={"w-[200px]"}>Discount:</span>
              </div>
              <div className={"flex items-center"}>
                <select
                  value={discountType}
                  className={"select select-bordered"}
                  onChange={(e) =>
                    setDiscountType(e?.target?.value as "%" | "$")
                  }>
                  <option value={"$"}>$</option>
                  <option value={"%"}>%</option>
                </select>
                <div className={"max-w-[70px]"}>
                  <CustomCurrencyInput
                    value={discount}
                    setValue={setDiscount}
                    prefix={""}
                  />
                </div>
                <div className={"ml-2 text-base font-semibold text-gray-800"}>
                  $
                  {(discountValue || 0).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </div>
              </div>
            </div>
            <div
              className={
                "mb-2 flex w-full items-center justify-between gap-2 text-base font-semibold text-gray-800"
              }>
              <div className={"w-[200px]"}>{taxRate?.[0]?.taxName}:</div>
              <div className={"flex items-center gap-2"}>
                <CurrencyInput
                  value={taxRateValue}
                  prefix={"%"}
                  className={"input input-bordered input-sm max-w-[100px]"}
                  onValueChange={(value) => {
                    const transformedValue = parseFloat(value || "0");
                    setTaxRateValue(transformedValue);
                  }}
                />
                {isIncludeTax && (
                  <div
                    className={
                      "input input-bordered flex h-fit min-h-[10px] items-center gap-2  whitespace-nowrap rounded-md px-2 text-gray-500"
                    }>
                    <span className={"font-normal"}>
                      Includes {taxRate?.[0]?.taxName || ""} on
                    </span>
                    <span className={"font-semibold"}>
                      ${" "}
                      {(subtotal - taxValue - discountValue).toLocaleString(
                        "en-US",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        },
                      )}
                    </span>
                  </div>
                )}
                <div>
                  $
                  {(taxValue || 0).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </div>
              </div>
            </div>
            <div
              className={
                "mb-2 flex w-full justify-between text-base font-semibold text-gray-800"
              }>
              <span className={"w-[200px]"}>Total:</span> $
              {total.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
            <div
              className={
                "mb-2 flex w-full justify-between text-base font-semibold text-gray-800"
              }>
              <span className={"w-[200px]"}>Payment received:</span> $
              {(paymentsRecieved || 0).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
            <div
              className={
                "mb-2 flex w-full items-end justify-between text-base font-semibold text-gray-800"
              }>
              <span className={"w-[200px]"}>Balance due:</span>{" "}
              <span className={"text-3xl font-bold"}>
                $
                {(balanceDue || 0).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>
            </div>
          </div>
        </div>
        <div className={"flex w-full justify-end gap-4"}>
          <Button
            label={"Cancel"}
            onClick={() => setView("details")}
            colorType={"ghost"}
            extraClasses={"normal-case"}
          />
          <Button
            label={"Save"}
            onClick={update}
            extraClasses={"normal-case"}
          />
        </div>
      </FormSection>
      <div className={"text-base font-semibold text-gray-800"}>
        {moment(invoice?.createdAt).format("MM/DD/YYYY")} Invoice created by{" "}
        {invoice?.createdByUser &&
          `${invoice?.createdByUser?.userProfile?.firstName} ${invoice?.createdByUser?.userProfile?.lastName}`}
      </div>
      {showAll && !!allItems?.length && (
        <BillableTime
          isLoading={isLoading}
          currentContact={currentContact}
          collapsedItems={collapsedItems}
          setCollapsedItems={setCollapsedItems}
          works={allItems}
          billedWorks={billedWorks}
          onAdd={addWorkItems}
          onClose={() => setShowAll(false)}
          isActive={showAll}
        />
      )}
    </>
  );
};

export default EditInvoice;
