import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import classNames from "classnames";
import CustomSelect from "../../components/select/Select";
import { SelectOptionType } from "../../types/types";
import Button from "../../components/button/Button";
import { HiXMark } from "react-icons/hi2";
import { Period, SelectDate } from "../billing/SelectDate";
import { getInitialsFromFullName } from "../../constants/constants";
import { useGetUsersQuery } from "../../slices/UserSlice";
import { getLinkedFirm } from "../../sessionStorage/sessionStorage";
import { useGetSearchCustomersQuery } from "../../slices/ContactsSlice";
import { useAppSelector } from "../../redux/redux";
import { useDebounce } from "usehooks-ts";
import { TimePeriod } from "../../hooks/useDetectTimePeriod";
import { Time } from "./InvoiceTime";
import { useGetWorkTypesQuery } from "../../slices/WorkTypeSlice";

type Props = {
  filter: Time;
  setFilter: Dispatch<SetStateAction<Time>>;
};
export const TimeFilters: FC<Props> = ({ filter, setFilter }) => {
  const [searchClients, setSearchClients] = useState("");
  const [searchWorks, setSearchWorks] = useState("");
  const [filterStatus, setFilterStatus] = useState<SelectOptionType>();

  const { user } = useAppSelector((state) => state.appReducer);

  const { data: workTypes, isLoading: isLoadingWorkTypes } =
    useGetWorkTypesQuery({ orgId: getLinkedFirm()?.orgId || "" });

  const debounceSearchClient = useDebounce(searchClients, 300);

  const { data: users, isLoading: isLoadingUsers } = useGetUsersQuery(
    getLinkedFirm()?.orgId || "",
  );
  const { data: contacts, isLoading: isLoadingContacts } =
    useGetSearchCustomersQuery({
      query: debounceSearchClient,
      orgId: getLinkedFirm()?.orgId || "",
      userId: user.id,
    });

  const usersOptions = (): SelectOptionType[] => {
    const options: SelectOptionType[] = [];
    if (users) {
      users?.forEach((item) => {
        options.push({
          avatar: getInitialsFromFullName(
            `${item?.userProfile?.firstName} ${item?.userProfile?.lastName}`,
          ),
          label: `${item?.userProfile?.firstName} ${item?.userProfile?.lastName}`,
          value: `${item?.id}`,
        });
      });
    }
    return options;
  };
  const workTypeOptions = (): SelectOptionType[] => {
    const options: SelectOptionType[] = [];
    if (workTypes) {
      workTypes?.forEach((item) => {
        options.push({
          label: item.name,
          value: item.id,
        });
      });
    }
    return options;
  };
  const clientOptions = (): SelectOptionType[] => {
    const options: SelectOptionType[] = [];
    if (contacts) {
      contacts.forEach((contact) => {
        options.push({
          value: contact?.id,
          label: `${contact.name}`,
          avatar: getInitialsFromFullName(contact?.name),
          avatarColor: contact.businessContactId ? "purple" : "blue",
        });
      });
    }

    return options;
  };
  const clearValues = () => {
    setFilter({
      filterByDate: [TimePeriod.LastYear],
      filterByWorkType: [],
      filterByClient: [],
      filterByAssignee: [],
      filterByStatus: null,
      fromDate: null,
      toDate: null,
      orgId: null,
      sortOrder: "",
      sortBy: "",
    });
  };
  const isShowClearButton =
    filter?.filterByDate[0] !== TimePeriod.LastYear ||
    !!filter?.filterByAssignee.length ||
    !!filter?.filterByWorkType.length ||
    !!filter?.filterByClient.length ||
    filter?.filterByStatus !== null;

  useEffect(() => {
    if (filterStatus?.value === "Saved") {
      setFilter((prev) => ({
        ...prev,
        filterByStatus: false,
      }));
    }
    if (filterStatus?.value === "Billed") {
      setFilter((prev) => ({
        ...prev,
        filterByStatus: true,
      }));
    }
  }, [filterStatus]);

  return (
    <div
      className={classNames(
        "grid w-full items-end gap-4",
        isShowClearButton
          ? "grid-cols-[repeat(3,max-content)] xl:grid-cols-[repeat(6,max-content)]"
          : "grid-cols-[repeat(3,max-content)] xl:grid-cols-[repeat(5,max-content)]",
      )}>
      <div>
        <div className={"flex flex-col"}>
          <div
            className={"mb-1 text-sm font-semibold normal-case text-gray-800"}>
            Date
          </div>
          <div className={"min-w-[150px]"}>
            <SelectDate
              setValue={(value) =>
                setFilter((prev) => ({
                  ...prev,
                  filterByDate: value.period ? [value.period] : [],
                  fromDate: value.from,
                  toDate: value.to,
                }))
              }
              value={{
                to: filter.toDate,
                from: filter.fromDate,
                period: filter.filterByDate[0],
              }}
            />
          </div>
        </div>
      </div>
      <div className={"min-w-[150px]"}>
        <div className={"mb-0.5 text-base"}>Work type</div>
        <CustomSelect
          options={workTypeOptions()}
          value={filter?.filterByWorkType}
          onChange={(newValue) => {
            const value = newValue as SelectOptionType[];
            setFilter((prev) => ({ ...prev, filterByWorkType: value }));
          }}
          inputValue={searchWorks}
          onChangeInput={setSearchWorks}
          isMulti
          removeSelectIcon
          isLoading={isLoadingWorkTypes}
          placeholder={"Search..."}
        />
      </div>
      <div className={"min-w-[150px]"}>
        <div className={"mb-0.5 text-base"}>Assignee</div>
        <CustomSelect
          options={usersOptions()}
          value={filter?.filterByAssignee}
          onChange={(newValue) => {
            const value = newValue as SelectOptionType[];
            setFilter((prev) => ({ ...prev, filterByAssignee: value }));
          }}
          inputValue={searchClients}
          onChangeInput={setSearchClients}
          isMulti
          isLoading={isLoadingUsers}
        />
      </div>
      <div className={"min-w-[150px]"}>
        <div className={"mb-0.5 text-base"}>Client</div>
        <CustomSelect
          options={clientOptions()}
          value={filter?.filterByClient}
          onChange={(newValue) => {
            const value = newValue as SelectOptionType[];
            setFilter((prev) => ({ ...prev, filterByClient: value }));
          }}
          inputValue={searchClients}
          onChangeInput={setSearchClients}
          isMulti
          isLoading={isLoadingContacts}
        />
      </div>
      <div className={"min-w-[150px]"}>
        <div className={"mb-0.5 text-base"}>Status</div>
        <CustomSelect
          options={[
            { value: "Billed", label: "Billed" },
            { value: "Saved", label: "Saved" },
          ]}
          value={filterStatus || null}
          onChange={(newValue) => setFilterStatus(newValue as SelectOptionType)}
        />
      </div>
      {isShowClearButton && (
        <div>
          <Button
            label={"Clear"}
            onClick={clearValues}
            colorType={"ghost"}
            icon={<HiXMark size={20} />}
          />
        </div>
      )}
    </div>
  );
};
