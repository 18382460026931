import React, { FC, useState } from "react";
import { HiEllipsisVertical, HiPlus } from "react-icons/hi2";
import { Link, useSearchParams } from "react-router-dom";

import { AvatarPlaceholder } from "../../../../../../../../components/avatar/AvatarPlaceholder";
import Button from "../../../../../../../../components/button/Button";
import { Card } from "../../../../../../../../components/card/Card";
import { LottieLoading } from "../../../../../../../../components/graphics/LottieLoading";
import NoItems from "../../../../../../../../components/NoItems";
import { ContactsSvg } from "../../../../../../../../components/svg/ContactSvg";
import {
  getInitialsFromFullName,
  inactiveStatusId,
} from "../../../../../../../../constants/constants";
import { getLinkedFirm } from "../../../../../../../../sessionStorage/sessionStorage";
import {
  useDeleteContactLinkedProfileMutation,
  useGetContactLinkedProfilesQuery,
} from "../../../../../../../../slices/LinkedProfilesSlice";
import {
  IContactProfile,
  ILinkedProfile,
} from "../../../../../../../../types/types";
import ErrorPopup from "../../../../../../modals/ErrorPopup";
import { navigateToContact } from "../../../../../../utils/navigateToContacts";
import { LinkedProfileModal } from "../../../../../../modals/LinkdeProfileModal";

type Props = {
  contact: IContactProfile;
};

export const ContactLinkedProfilesWidget: FC<Props> = ({ contact }) => {
  const { data: contactLinkedProfiles, isFetching } =
    useGetContactLinkedProfilesQuery({
      orgId: getLinkedFirm()?.orgId,
      contactId: contact?.contactId || "",
    });
  const [deleteLinkedProfile, { isLoading: isLoadingDelete }] =
    useDeleteContactLinkedProfileMutation();

  const [currentLinkedProfile, setCurrentLinkedProfile] =
    useState<ILinkedProfile | null>(null);
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isOpenError, setIsOpenError] = useState(false);

  const isActive = contact?.contactStatusTypeEnumId !== inactiveStatusId;

  return (
    <>
      <Card extraClasses={"shadow-box rounded-[6px]"}>
        <div className={"max-w-full pt-4"}>
          <div className={"flex w-full justify-between px-6"}>
            <div className={"text-lg font-bold"}>Linked profiles</div>
            <Button
              label={""}
              icon={<HiPlus />}
              size={"custom"}
              colorType={"outline"}
              extraClasses={"btn-sm px-2"}
              onClick={() =>
                !isActive ? setIsOpenError(true) : setIsOpenAdd(true)
              }
            />
          </div>
          <div>
            {isFetching ? (
              <div className={"p-4"}>
                <LottieLoading />
              </div>
            ) : !contactLinkedProfiles?.length ? (
              <div className={"mt-4 p-6 pt-0"}>
                <NoItems
                  icon={<ContactsSvg />}
                  message={"There are no linked profile"}
                />
              </div>
            ) : (
              <div className={"max-h-[300px] overflow-y-auto pt-5"}>
                {contactLinkedProfiles?.map((item) => (
                  <div
                    className={
                      "grid grid-cols-[1fr_max-content] items-center justify-between gap-2 px-4 py-2"
                    }
                    key={item.id}>
                    <div
                      className={
                        "grid grid-cols-[max-content_1fr] items-center gap-2 text-sm"
                      }>
                      <AvatarPlaceholder
                        type={
                          item?.linkedCustomer?.businessContactId
                            ? "purple"
                            : "blue"
                        }
                        size={"extra-small"}
                        label={getInitialsFromFullName(
                          item?.linkedCustomer?.name || "",
                        )}
                      />
                      <div className={"truncate"}>
                        <Link
                          to={navigateToContact({
                            type: item?.linkedCustomer?.contactId
                              ? "contact"
                              : "organization",
                            customerId: item?.linkedCustomerId || "",
                            id: item?.linkedCustomer?.businessContactId || "",
                          })}
                          className={
                            "link-primary truncate text-sm font-medium hover:underline"
                          }>
                          {item?.linkedCustomer?.name}
                        </Link>
                        <div className={"truncate text-gray-400"}>
                          {item?.relation}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className={"dropdown dropdown-end dropdown-left"}>
                        <div>
                          <Button
                            label={""}
                            colorType={"ghost"}
                            size={"custom"}
                            onClick={(e) => e.stopPropagation()}
                            icon={<HiEllipsisVertical size={24} />}
                            extraClasses={"min-h-fit h-fit w-fit p-1"}
                          />
                        </div>
                        <div
                          className={
                            "dropdown-content rounded-[5px] border border-gray-300 bg-white text-sm shadow-box"
                          }>
                          <div
                            onMouseDown={() => {
                              setCurrentLinkedProfile(item);
                              setIsOpenAdd(true);
                            }}
                            className={
                              "cursor-pointer rounded-[5px] px-[15px] py-[5px] text-center hover:bg-gray-300"
                            }>
                            Edit
                          </div>
                          <div
                            onMouseDown={() => {
                              if (!isLoadingDelete) {
                                deleteLinkedProfile(item);
                              }
                            }}
                            className={
                              "cursor-pointer rounded-[5px] px-[15px] py-[5px] text-center hover:bg-gray-300"
                            }>
                            Delete
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </Card>
      {isOpenAdd && (
        <LinkedProfileModal
          isActive={isOpenAdd}
          onClose={() => setIsOpenAdd(false)}
          contactId={contact?.contactId || ""}
          defaultValues={
            currentLinkedProfile
              ? {
                  id: currentLinkedProfile?.id || "",
                  customer: {
                    value: currentLinkedProfile?.linkedCustomerId,
                    label: currentLinkedProfile?.linkedCustomer?.name || "",
                    avatar: getInitialsFromFullName(
                      currentLinkedProfile?.linkedCustomer?.name || "",
                    ),
                    avatarColor: currentLinkedProfile?.linkedCustomer
                      ?.businessContactId
                      ? "purple"
                      : "blue",
                  },
                  relationship: currentLinkedProfile?.relation || "",
                }
              : undefined
          }
        />
      )}
      <ErrorPopup
        closePopup={() => setIsOpenError(false)}
        isOpen={isOpenError}
      />
    </>
  );
};
