import classNames from "classnames";
import React, { FC, useEffect, useState } from "react";
import { HiEllipsisVertical, HiOutlineBars3 } from "react-icons/hi2";
import { toast } from "react-toastify";

import { AvatarPlaceholder } from "../../../../../../components/avatar/AvatarPlaceholder";
import Button from "../../../../../../components/button/Button";
import { Dropdown } from "../../../../../../components/dropdown/new-dropdown/Dropdown";
import { getInitials } from "../../../../../../constants/constants";
import { WorkTaskModel } from "../../../../../../factories/work/model/work-task.model";
import {
  useDeleteWorkTaskMutation,
  useReassignWorkTaskMutation,
  useUpdateWorkTaskMutation,
  useUpdateWorkTaskStatusMutation,
} from "../../../../../../slices/WorkTasksSlice";
import { IUser } from "../../../../../../types/types";
import { WorkTaskModal } from "../../../../modals/WorkTaskModel";
import { useAppSelector } from "../../../../../../redux/redux";
import { useParams } from "react-router-dom";
import moment from "moment/moment";
import { Controller, useForm } from "react-hook-form";
import { StyledDatePicker } from "../../../../../../components/datepicker/datepicker";
import { WorkDetailsModel } from "../../../../../../factories/work/model/work-details.model";
import { WorkStatus } from "../work-main/WorkStatus";
import { RxDragHandleDots2 } from "react-icons/rx";

type Props = {
  workTask: WorkTaskModel;
  users: IUser[];
  work: WorkDetailsModel;
};
type FormData = {
  name: string;
  description: string;
  dueDate: string;
  startDate: string;
};
export const WorkTask: FC<Props> = ({ workTask, users, work }) => {
  const { user } = useAppSelector((state) => state.appReducer);
  const { workId } = useParams();

  const [reassignWorkTask] = useReassignWorkTaskMutation();
  const [updateWorkTaskStatus, { isSuccess, isError, data: updatedWorkTask }] =
    useUpdateWorkTaskStatusMutation();
  const [deleteWorkTask] = useDeleteWorkTaskMutation();
  const [updateContactNote] = useUpdateWorkTaskMutation();

  const [previewIsDone, setPreviewDone] = useState<boolean | null>(null);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleChangeWorkTaskStatus = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPreviewDone(e.target.checked);
    updateWorkTaskStatus({
      taskId: workTask?.id,
      isDone: e.target.checked,
      updatedByUserId: user.id,
    });
  };
  const { control, getValues, setValue } = useForm<FormData>({
    defaultValues: {
      dueDate: moment(workTask?.dueDate).isValid()
        ? moment(workTask?.dueDate).toISOString()
        : moment().toISOString(),
    },
  });
  const today = new Date();
  const dateToCompare = new Date(workTask.dueDate);
  const todayDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
  );
  const compareDate = new Date(
    dateToCompare.getFullYear(),
    dateToCompare.getMonth(),
    dateToCompare.getDate(),
  );

  useEffect(() => {
    setValue(
      "dueDate",
      moment(workTask?.dueDate).isValid()
        ? moment(workTask?.dueDate).toISOString()
        : moment().toISOString(),
    );
  }, [workTask]);

  const showIsDoneWorkTask = (): boolean => {
    if (updatedWorkTask?.isDone === workTask?.isDone) {
      return workTask?.isDone;
    }
    if (previewIsDone !== null) {
      return previewIsDone;
    }
    if (updatedWorkTask) {
      return updatedWorkTask?.isDone;
    }
    return workTask?.isDone;
  };
  const onChangeDueDate = (data: FormData) => {
    updateContactNote({
      ...workTask,
      dueDate: data.dueDate,
    });
  };
  useEffect(() => {
    if (workTask?.status?.name === "Completed") {
      updateWorkTaskStatus({
        taskId: workTask?.id,
        isDone: true,
        updatedByUserId: user.id,
      });
    }
    if (workTask?.status?.name === "Waiting") {
      updateWorkTaskStatus({
        taskId: workTask?.id,
        isDone: false,
        updatedByUserId: user.id,
      });
    }
  }, [workTask?.status]);

  useEffect(() => {
    if (isSuccess) {
      setPreviewDone(null);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      setPreviewDone(null);
      toast("Something went wrong", {
        type: "error",
        pauseOnHover: false,
      });
    }
  }, [isError]);

  return (
    <>
      <div
        className={classNames(
          "grid w-full  grid-cols-[2fr_2fr_59fr_2fr] border-t border-gray-200 bg-base-100 py-2 pr-2",
          !isCollapsed && "items-center",
        )}>
        <RxDragHandleDots2 size={24} className={"mr-2 text-gray-200"} />

        <input
          type={"checkbox"}
          className={
            "checkbox mr-3 border-gray-300 [--chkbg:--b1] [--chkfg:--a] checked:border-gray-300 checked:bg-base-100"
          }
          checked={showIsDoneWorkTask()}
          onChange={handleChangeWorkTaskStatus}
        />

        <div
          className={classNames(
            " text-sm",
            workTask?.isDone && "line-through",
          )}>
          <div>
            <div className={"flex w-full justify-between"}>
              <div className={"font-bold"}>{workTask?.name}</div>
            </div>
            <div className={"text-gray-500"}>{workTask?.description}</div>
          </div>

          <div className={"mt-4 flex justify-between"}>
            <div className={"flex items-center gap-4"}>
              <div className={"h-10 cursor-pointer"}>
                <div>
                  <Dropdown
                    isCloseOnClick
                    triggerElement={
                      <Button
                        label={""}
                        colorType={"ghost"}
                        size={"custom"}
                        icon={
                          <AvatarPlaceholder
                            label={getInitials(
                              workTask?.assignedUser?.userProfile?.firstName ||
                                "",
                              workTask?.assignedUser?.userProfile?.lastName ||
                                "",
                            )}
                            username={`${workTask?.assignedUser?.userProfile?.firstName} ${workTask?.assignedUser?.userProfile?.lastName}`}
                            size={"super-small"}
                            isShowUsername
                          />
                        }
                        extraClasses={
                          "text-purple border btn-xs p-0.5 hover:bg-transparent"
                        }
                      />
                    }>
                    <div
                      className={
                        "rounded-[5px] border border-gray-300 bg-white text-sm shadow-box"
                      }>
                      <div
                        className={
                          "link-primary border-b-[1px] border-b-primary px-4 py-2 text-sm"
                        }>
                        Reassign subtask to:
                      </div>
                      <div className={"max-h-[200px] overflow-y-auto"}>
                        {users?.map((item) => (
                          <div
                            key={item?.id}
                            onClick={() =>
                              reassignWorkTask({
                                name: workTask?.name || "",
                                id: workTask?.id || "",
                                assignedUserId: item?.id,
                                assignedByUserId: user?.id,
                                jobId: workId || "",
                              })
                            }
                            className={
                              "flex cursor-pointer items-center gap-1 px-4 py-2 hover:bg-blue-400 hover:text-white"
                            }>
                            <AvatarPlaceholder
                              size={"super-small"}
                              label={getInitials(
                                item?.userProfile?.firstName || "",
                                item?.userProfile?.lastName || "",
                              )}
                            />
                            <div
                              className={
                                "whitespace-pre-wrap"
                              }>{`${item?.userProfile?.firstName} ${item?.userProfile?.lastName}`}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Dropdown>
                </div>
              </div>
              <div className={"flex min-w-fit items-center gap-2"}>
                <div className={"flex justify-end"}>
                  <WorkStatus workTask={workTask} workTypeId={work.serviceId} />
                </div>
              </div>
            </div>
            <div
              className={
                "flex items-center justify-end gap-2 text-end text-gray-800"
              }>
              <div
                className={classNames(
                  "flex items-center text-[14px] font-semibold",
                  compareDate < todayDate
                    ? "text-orange-300"
                    : "text-[#9FA1AD]",
                )}>
                Due:
              </div>

              <div className={"flex w-fit items-center"}>
                <Controller
                  name={"dueDate"}
                  control={control}
                  render={({ field: { value, onChange } }) => {
                    const handleDateChange = (date: Date | null) => {
                      if (date instanceof Date && !isNaN(date.getTime())) {
                        const isoDate = moment(date).toISOString();
                        onChange(isoDate);

                        const formData: FormData = {
                          ...getValues(),
                          dueDate: isoDate,
                        };

                        onChangeDueDate(formData);
                      }
                    };
                    return (
                      <StyledDatePicker
                        value={value ? moment(value).toDate() : new Date()}
                        onChange={handleDateChange}
                        maxDate={moment(work.dueDate).toDate()}
                        styleType={"ghost"}
                        fontWeight={"500"}
                        width={"130px"}
                        color={
                          compareDate < todayDate
                            ? "rgb(253 186 116)"
                            : "#9FA1AD"
                        }
                        textAlign={"end"}
                        format={"dd MMMM, yyyy"}
                      />
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div>
          <Dropdown
            triggerElement={
              <Button
                label={""}
                colorType={"ghost"}
                size={"custom"}
                icon={<HiEllipsisVertical size={20} />}
                extraClasses={"btn-xs p-0.5"}
              />
            }>
            <div
              className={
                "rounded-[5px] border border-gray-300 bg-white text-sm shadow-box"
              }>
              <div
                onClick={() => setIsOpenUpdate(true)}
                className={
                  "cursor-pointer rounded-[5px] px-[15px] py-[5px] text-center hover:bg-gray-300"
                }>
                Edit
              </div>
              <div
                onClick={() => deleteWorkTask(workTask)}
                className={
                  "cursor-pointer rounded-[5px] px-[15px] py-[5px] text-center hover:bg-gray-300"
                }>
                Delete
              </div>
            </div>
          </Dropdown>
        </div>
      </div>
      {isOpenUpdate && (
        <WorkTaskModal
          isActive={isOpenUpdate}
          onClose={() => setIsOpenUpdate(false)}
          defaultValues={workTask}
          work={work}
          users={users}
        />
      )}
    </>
  );
};
