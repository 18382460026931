import moment from "moment/moment";
import React, { FC, useState } from "react";
import { HiPlus } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";

import Button from "../../../../../../../../components/button/Button";
import { Card } from "../../../../../../../../components/card/Card";
import { LottieLoading } from "../../../../../../../../components/graphics/LottieLoading";
import NoItems from "../../../../../../../../components/NoItems";
import ContactNoteSvg from "../../../../../../../../components/svg/ContactNoteSvg";
import { updatePaymentStatusFactory } from "../../../../../../../../factories/billing/factory-update-payment-status";
import {
  IPayment,
  SortEnum,
  useGetPaymentsQuery,
  useGetPaymentStatusesQuery,
  useUpdatePaymentMutation,
} from "../../../../../../../../services/BillingService";
import { getLinkedFirm } from "../../../../../../../../sessionStorage/sessionStorage";
import DropStatus from "../../../../../../../billing/DropStatus";
import ErrorPopup from "../../../../../../modals/ErrorPopup";

type Props = {
  customerId: string;
};

export const OrganizationPaymentsWidget: FC<Props> = ({ customerId }) => {
  const navigate = useNavigate();

  const { data: contactPayments, isFetching } = useGetPaymentsQuery({
    orgId: getLinkedFirm()?.orgId,
    filterByDate: [],
    sortBy: SortEnum.PaymentDate,
    sortOrder: "asc",
    filterByStatus: [],
    filterByClient: [customerId || ""],
    filterByAssignee: [],
    filterByLabel: [],
  });

  const { data: statuses, isLoading: isLoadingStatuses } =
    useGetPaymentStatusesQuery(getLinkedFirm()?.orgId || "");

  const [updatePayment, { isLoading: isLoadingUpdate }] =
    useUpdatePaymentMutation();

  const [activePaymentId, setActivePaymentId] = useState("");

  const updateStatus = (statusId: string, payment: IPayment) => {
    const body = updatePaymentStatusFactory.createModelFromDto({
      payment,
      status: statusId || null,
    });
    updatePayment(body);
  };

  const [isOpenError, setIsOpenError] = useState(false);

  const isActive = true;

  return (
    <>
      <Card extraClasses={"shadow-box rounded-[6px]"}>
        <div className={"max-w-full pt-4"}>
          <div className={"mb-4 flex w-full justify-between px-6"}>
            <div className={"text-lg font-bold"}>Payments</div>
            <Button
              label={""}
              icon={<HiPlus />}
              size={"custom"}
              colorType={"outline"}
              extraClasses={"btn-sm px-2"}
              onClick={() =>
                !isActive
                  ? setIsOpenError(true)
                  : navigate(`/billing/payments/add?customerId=${customerId}`)
              }
            />
          </div>
          <div>
            {isFetching ? (
              <div className={"p-4"}>
                <LottieLoading />
              </div>
            ) : !contactPayments?.length ? (
              <div className={"p-6 pt-0"}>
                <NoItems
                  icon={<ContactNoteSvg />}
                  message={"There are no payments"}
                />
              </div>
            ) : (
              <div className={"max-h-[300px] overflow-x-auto overflow-y-auto"}>
                <table className={"w-full"}>
                  <thead>
                    <tr className={"border-b-[1px] border-gray-300"}>
                      <th
                        className={
                          "px-4 py-2 text-start text-xs font-bold text-gray-800 2xl:px-3"
                        }>
                        Payment date
                      </th>
                      <th
                        className={
                          "px-4 py-2 text-start text-xs font-bold text-gray-800 2xl:px-3"
                        }>
                        Amount
                      </th>
                      <th
                        className={
                          "px-4 py-2 text-start text-xs font-bold text-gray-800 2xl:px-3"
                        }>
                        Method
                      </th>
                      <th
                        className={
                          "px-4 py-2 text-start text-xs font-bold text-gray-800 2xl:px-3"
                        }>
                        Label
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {contactPayments?.map((payment) => (
                      <tr
                        key={payment?.id}
                        onClick={() =>
                          navigate("/billing/payments/" + payment.id)
                        }
                        className={
                          "cursor-pointer border-b-[1px] border-gray-300 last-of-type:border-none hover:bg-gray-100"
                        }>
                        <td
                          className={
                            "px-4 py-2 text-xs text-gray-800 2xl:px-3"
                          }>
                          {moment(payment?.paymentDate).format("MM/DD/YYYY")}
                        </td>
                        <td
                          className={
                            "px-4 py-2 text-xs text-gray-800 2xl:px-3"
                          }>
                          $
                          {(payment?.amount || 0).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                        <td
                          className={
                            "px-4 py-2 text-xs text-gray-800 2xl:px-3"
                          }>
                          {payment?.paymentMethod?.paymentMethod}
                        </td>
                        <td
                          className={
                            "px-4 py-2 text-xs text-gray-800 2xl:px-3"
                          }>
                          <DropStatus
                            isSmall
                            invoiceStatus={null}
                            isLoading={
                              (isLoadingUpdate || isFetching) &&
                              payment?.id === activePaymentId
                            }
                            paymentStatus={payment?.paymentStatus || null}
                            statuses={statuses || []}
                            selectStatus={(status) => {
                              setActivePaymentId(payment?.id);
                              updateStatus(status?.id || "", payment);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </Card>
      <ErrorPopup
        closePopup={() => setIsOpenError(false)}
        isOpen={isOpenError}
      />
    </>
  );
};
