export enum TimeListSort {
  WorkName = "Name",
  Contact = "Customer",
  WorkType = "WorkType",
  TotalTime = "Duration",
  Date = "Date",
  Total = "Total",
  Assignee = "AssignedUser",
  Status = "Status",
  Notes = "Notes",
}

export enum TimeListTitle {
  WorkName = "Work Name",
  Contact = "Contact",
  WorkType = "Work Type",
  TotalTime = "Total Time",
  Date = "Date",
  Total = "Total",
  Assignee = "Assignee",
  Status = "Status",
  Notes = "Notes",
}

export type TimeListHeaderItem = {
  title: TimeListTitle;
  sort: TimeListSort;
  hideSort?: boolean;
};

export const timeListHeaderItems: TimeListHeaderItem[] = [
  {
    title: TimeListTitle.WorkName,
    sort: TimeListSort.WorkName,
  },
  {
    title: TimeListTitle.Contact,
    sort: TimeListSort.Contact,
  },
  {
    title: TimeListTitle.WorkType,
    sort: TimeListSort.WorkType,
  },
  {
    title: TimeListTitle.TotalTime,
    sort: TimeListSort.TotalTime,
  },
  {
    title: TimeListTitle.Date,
    sort: TimeListSort.Date,
  },
  {
    title: TimeListTitle.Total,
    sort: TimeListSort.Total,
  },
  {
    title: TimeListTitle.Status,
    sort: TimeListSort.Status,
    hideSort: true,
  },
  {
    title: TimeListTitle.Assignee,
    sort: TimeListSort.Assignee,
  },
  {
    title: TimeListTitle.Notes,
    sort: TimeListSort.Notes,
    hideSort: true,
  },
];
