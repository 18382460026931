import React, { FC, FocusEventHandler, useEffect, useState } from "react";
import Select, {
  components,
  GroupBase,
  MenuListProps,
  MultiValue,
  OptionProps,
} from "react-select";

import { SelectOptionType } from "../../types/types";
import { AvatarPlaceholder } from "../avatar/AvatarPlaceholder";
import { LuPlusCircle } from "react-icons/lu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { LottieLoading } from "../graphics/LottieLoading";

interface Props {
  isMulti?: true | undefined;
  withAvatar?: boolean;
  autoFocus?: boolean;
  options: SelectOptionType[];
  placeholder?: string;
  onChange: (newValue: MultiValue<SelectOptionType> | SelectOptionType) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  value: SelectOptionType[] | SelectOptionType | null;
  inputValue?: string;
  onChangeInput?: (inputValue: string) => void;
  fullWidth?: boolean;
  clearValueCloseMenu?: boolean;
  removeSelectIcon?: boolean;
  isLoading?: boolean;
  small?: boolean;
  setIsOpenAddContact?: (value: boolean) => void;
  mail?: boolean;
  menuIsOpen?: boolean;
  height?: string;
  isSubtask?: boolean;
}

type MenuListPropsType = MenuListProps<
  SelectOptionType,
  true,
  GroupBase<SelectOptionType>
> & { setIsOpenAddContact?: (value: boolean) => void; isLoading?: boolean };

const MenuList = ({
  children,
  setIsOpenAddContact,
  isLoading,
  ...props
}: MenuListPropsType) => {
  return (
    <components.MenuList {...props}>
      {isLoading ? (
        <LottieLoading />
      ) : (
        <>
          {setIsOpenAddContact && (
            <div
              className={
                "flex cursor-pointer items-center gap-2 px-4 py-2 text-sm text-blue-600 hover:bg-blue-400 hover:text-white"
              }
              onClick={() => {
                if (setIsOpenAddContact) {
                  props.selectProps.onMenuClose();
                  setIsOpenAddContact(true);
                }
              }}>
              <div
                className={
                  "flex h-4 w-4 flex-nowrap items-center justify-center rounded-full border-[1px] border-blue-600"
                }>
                <FontAwesomeIcon
                  icon={faPlus}
                  className={"text-[10px] text-blue-600"}
                />
              </div>
              <div className={"whitespace-nowrap font-bold"}>
                Create new contact
              </div>
            </div>
          )}
          {children}
        </>
      )}
    </components.MenuList>
  );
};

type InputOptionProps = OptionProps<
  SelectOptionType,
  true,
  GroupBase<SelectOptionType>
>;

const InputOption = ({
  getStyles,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  data,
  isMulti,
  mail,
  ...rest
}: InputOptionProps & { mail?: boolean }) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#eee";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
    fontWeight: "500",
    fontSize: mail ? "12px" : "14px",
    textOverflow: "ellipsis",
    maxWidth: "100%",
    overflow: "hidden",
    cursor: "pointer",
    fontFamily:
      '"Noto-sans", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important',
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isMulti={isMulti}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      data={data}
      innerProps={props}>
      {isMulti && (
        <input
          type={"checkbox"}
          checked={isSelected}
          onChange={() => null}
          className={"checkbox-ms checkbox checkbox-primary mr-2"}
        />
      )}
      {data?.color && (
        <div>
          <div
            style={{ backgroundColor: data?.color }}
            className={"mr-2 h-[10px] w-[10px] rounded-full"}
          />
        </div>
      )}
      {data?.avatar && (
        <div className={"mr-2 font-normal"}>
          <AvatarPlaceholder
            size={"2xs"}
            label={data?.avatar}
            type={data?.avatarColor ? data?.avatarColor : "blue"}
          />
        </div>
      )}
      {data.value === "create" ? (
        <div
          className={
            "flex items-center gap-2 whitespace-nowrap font-bold text-blue-600"
          }>
          <LuPlusCircle size={16} />
          {children}
        </div>
      ) : (
        <div className={"grid grid-rows-[max-content_max-content]"}>
          <div className={data?.isBold ? "font-bold" : "!font-[500]"}>
            {children}
          </div>
          {data?.caption && (
            <div className={"truncate text-xs text-gray-500"}>
              {data?.caption}
            </div>
          )}
        </div>
      )}
    </components.Option>
  );
};

const CustomMultiValue = (props: any) => {
  const { children, selectProps } = props;
  const totalValues = selectProps?.value;
  const item = props?.data?.value;

  const indexItem = totalValues?.findIndex((i: any) => i?.value === item);

  const isNotFirst = indexItem !== 0;

  if (isNotFirst) {
    return indexItem > 1 ? null : <span>...</span>;
  }

  return <components.MultiValue {...props}>{children}</components.MultiValue>;
};

const CustomControl = (props: any) => {
  const { children, getValue, selectProps } = props;
  const value: SelectOptionType | null = getValue()?.[0];

  const withAvatar = selectProps?.styles?.control()?.paddingLeft === "8px";

  return (
    <components.Control {...props}>
      {value && withAvatar && (
        <div className={"mr-4"}>
          <AvatarPlaceholder
            size={"super-small"}
            label={value?.avatar || ""}
            type={value?.avatarColor}
          />
        </div>
      )}
      {children}
    </components.Control>
  );
};

const CustomSelect: FC<Props> = ({
  isMulti,
  options,
  onChange,
  onBlur,
  placeholder,
  value,
  inputValue,
  onChangeInput,
  fullWidth,
  clearValueCloseMenu = true,
  removeSelectIcon,
  small,
  isLoading,
  withAvatar,
  autoFocus,
  setIsOpenAddContact,
  mail,
  menuIsOpen,
  isSubtask,
  height,
}) => {
  const [inputValueState, setInputValueState] = useState(inputValue || "");

  useEffect(() => {
    if (onChangeInput) {
      onChangeInput(inputValueState);
    }
  }, [inputValueState]);

  const MenuListComponent = (
    props: MenuListProps<SelectOptionType, true, GroupBase<SelectOptionType>>,
  ) => {
    return (
      <MenuList
        {...props}
        setIsOpenAddContact={setIsOpenAddContact}
        className={height}
        children={props.children}
      />
    );
  };

  return (
    <Select
      options={options}
      value={value}
      onChange={onChange}
      onMenuClose={() => {
        if (!clearValueCloseMenu) {
          setInputValueState(inputValue || "");
        }
      }}
      menuIsOpen={menuIsOpen}
      onBlur={onBlur}
      placeholder={placeholder}
      inputValue={inputValueState}
      onInputChange={(newValue) => {
        setInputValueState(newValue);
      }}
      isLoading={isLoading}
      isMulti={isMulti}
      autoFocus={autoFocus}
      closeMenuOnSelect={!isMulti}
      hideSelectedOptions={false}
      tabSelectsValue={false}
      menuPortalTarget={document.body}
      components={{
        Option: (props) => (
          <InputOption mail={mail} {...props} children={props.children} />
        ),
        MultiValue: CustomMultiValue,
        Control: CustomControl,
        MenuList: setIsOpenAddContact ? MenuListComponent : MenuList,
        IndicatorSeparator: isSubtask ? () => null : undefined,
      }}
      styles={{
        indicatorsContainer: (base) =>
          removeSelectIcon
            ? { display: "none" }
            : {
                ...base,
                maxHeight: isSubtask
                  ? "38px"
                  : small && mail
                  ? "40px"
                  : small
                  ? "32px"
                  : undefined,
              },
        input: (base) => ({
          ...base,
          fontSize: small ? "14px" : "16px",
          border: "none",
          cursor: "pointer",
        }),
        menuPortal: (base) => ({
          ...base,
          zIndex: 9999,
        }),
        placeholder: (base) => ({
          ...base,
          fontSize: small ? "14px" : "16px",
          fontFamily:
            '"Noto-sans", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important',
        }),
        singleValue: (base) => ({
          ...base,
          fontSize: isSubtask ? "16px" : mail ? "12px" : "14px",
          fontWeight: 500,
          cursor: "pointer",
          fontFamily:
            '"Noto-sans", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important',
        }),
        multiValue: (base) => ({
          ...base,
          maxWidth: "150px",
          textOverflow: "ellipsis",
        }),
        valueContainer: (base) => ({
          ...base,
          flexWrap: "nowrap",
          maxHeight: isSubtask ? "38px" : small ? "32px" : undefined,
          paddingLeft: withAvatar ? "0px" : "8px",
          cursor: "pointer",
        }),
        control: (base, state) => ({
          ...base,
          minHeight: isSubtask
            ? "38px"
            : small && mail
            ? "40px"
            : small
            ? "32px"
            : "48px",
          maxHeight: small && mail ? "40px" : small ? "32px" : undefined,
          maxWidth: fullWidth ? "100%" : "300px",
          cursor: "pointer",
          boxShadow:
            state?.isFocused && isSubtask
              ? "0 0 0 1px #EAEDF3A3"
              : state?.isFocused
              ? "0 0 0 1px #3e4bff"
              : "none",
          borderColor:
            state?.isFocused && isSubtask
              ? "#EAEDF3A3"
              : state?.isFocused
              ? "#3e4bff"
              : "#1f293733",
          borderRadius: "0.5rem",
          backgroundColor: isSubtask ? "#F8F9FB" : "",
          paddingLeft: withAvatar ? "8px" : "0px",
          "&:hover": {
            borderColor: isSubtask ? "none" : "#3e4bff",
          },
        }),
      }}
    />
  );
};

export default CustomSelect;
