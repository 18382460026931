import * as Xstate from "@xstate/react";
import React, { useState } from "react";
import { Navigate, Outlet, Route, Routes, useParams } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import { GlobalContext } from "../../app/stateMachines/GlobalContext";
import Button from "../../components/button/Button";
import { PageLayout } from "../../components/layouts/PageLayout";
import { useAppDispatch, useAppSelector } from "../../redux/redux";
import { timerSlice, updateTimer } from "../../services/TimerService";
import { CustomLink } from "../../support/CustomLink";
import { GetPageTitle } from "../../support/ScrollToTop";
import StartTimer from "./StartTimer";
import { TimesListView } from "./TimesListView";
import { ExcelExport } from "../../utils/ExportData";
import { timeFormatter } from "../../constants/constants";
import moment from "moment";

export const TimeView: React.FC<unknown> = () => {
  const { userInfoService } = React.useContext(GlobalContext);
  const [userInfoState] = Xstate.useActor(userInfoService);
  const { userInfoByEmail } = userInfoState.context;
  const { timer } = useAppSelector((state) => state.timerReducer);
  const [isOpenStart, setIsOpenStart] = useState(false);
  const { times } = useAppSelector((state) => state.appReducer);

  const { setStatusTimer } = timerSlice.actions;
  const dispatch = useAppDispatch();

  useEffectOnce(() => {
    document.title = GetPageTitle("Time");
  });

  const buttonTimerVariant = () => {
    if (timer.timerStatus === "ended" || timer.timerStatus === null) {
      return (
        <div className={"flex gap-2"}>
          <div className={"flex items-center"}>
            <ExcelExport
              data={
                times?.map((time) => ({
                  Work: time.work?.name,
                  Contact: time.contactId
                    ? `${time?.contact?.firstName} ${time?.contact?.lastName}`
                    : time?.businessContact?.companyName,
                  "Work Type": time.work?.service?.name,
                  "Total time": timeFormatter(time.duration),
                  Date: moment(time.date).format("YYYY-MM-DD"),
                  Assignee: `${time.assignedUser.userProfile.firstName} ${time.assignedUser.userProfile.lastName}`,
                  Notes: time.notes || "",
                })) || []
              }
              fileName={"Time"}
            />
          </div>
          <Button
            onClick={() => setIsOpenStart(true)}
            buttonType={"button"}
            label={"Start timer"}
            extraClasses={"normal-case"}
          />
        </div>
      );
    } else {
      return (
        <div className={"flex gap-2"}>
          <div className={"flex items-center"}>
            <ExcelExport
              data={
                times?.map((time) => ({
                  Work: time.work?.name,
                  Contact: time.contactId
                    ? `${time?.contact?.firstName} ${time?.contact?.lastName}`
                    : time?.businessContact?.companyName,
                  "Work Type": time.work?.service?.name,
                  "Total time": timeFormatter(time.duration),
                  Date: moment(time.date).format("YYYY-MM-DD"),
                  Assignee: `${time.assignedUser.userProfile.firstName} ${time.assignedUser.userProfile.lastName}`,
                  Notes: time.notes || "",
                })) || []
              }
              fileName={"Time"}
            />
          </div>
          <Button
            onClick={() => {
              timer.timerStatus === "started" &&
                dispatch(
                  updateTimer({
                    ...timer,
                    assignedUserId: null,
                    timerStatus: "paused",
                    assignedUserRelayId: `${userInfoByEmail?.id}`,
                  }),
                );
              dispatch(
                setStatusTimer({
                  timerStatus:
                    timer.timerStatus === "started" ? "paused" : "started",
                }),
              );
            }}
            buttonType={"button"}
            label={timer.timerStatus === "paused" ? "Continue" : "Pause"}
            extraClasses={"normal-case"}
          />
        </div>
      );
    }
  };
  return (
    <>
      <PageLayout title={"Time"}>
        <TimesListView buttonTimerVariant={buttonTimerVariant} />
        <div>
          <Outlet />
        </div>
      </PageLayout>
      {isOpenStart && (
        <StartTimer
          isOpen={isOpenStart}
          closeModal={() => setIsOpenStart(false)}
        />
      )}
    </>
  );
};
