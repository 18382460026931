import { ChevronDown } from "baseui/icon";
import classNames from "classnames";
import moment from "moment";
import { FC, useEffect, useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";

import { StyledDatePicker } from "../../components/datepicker/datepicker";
import { TimePeriod } from "../../hooks/useDetectTimePeriod";

export type Period = {
  period: TimePeriod | null;
  from: Date | null;
  to: Date | null;
};

type Props = {
  value: Period | null;
  setValue: (value: Period) => void;
};

const data = [
  { value: TimePeriod.LastYear, label: "Last 12 months" },
  { value: TimePeriod.YTD, label: "YTD" },
  { value: TimePeriod.LastMonth, label: "Last month" },
  { value: TimePeriod.ThisMonth, label: "This month" },
  { value: TimePeriod.Today, label: "Today" },
  { value: TimePeriod.Custom, label: "Custom" },
];

export const SelectDate: FC<Props> = ({ value, setValue }) => {
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [activeValue, setActiveValue] = useState(value?.period || null);
  const [fromDate, setFromDate] = useState<Date | null>(value?.from || null);
  const [toDate, setToDate] = useState<Date | null>(value?.to || null);

  const onClose = () => {
    if (activeValue !== TimePeriod.Custom) {
      setFromDate(null);
      setToDate(null);
      setIsOpen(false);
    }
  };

  useOnClickOutside(dropdownRef, onClose);

  const handleSelect = (period: TimePeriod) => {
    if (period === TimePeriod.Custom) {
      setActiveValue(period);
    } else {
      setValue({ period: period, from: null, to: null });
      setFromDate(null);
      setToDate(null);
      setIsOpen(false);
    }
  };

  const onApply = () => {
    setIsOpen(false);
    setValue({ period: activeValue, from: fromDate, to: toDate });
  };

  const onCancel = () => {
    setIsOpen(false);
    setActiveValue(value?.period || null);
    setFromDate(value?.from || null);
    setToDate(value?.to || null);
  };

  useEffect(() => {
    setActiveValue(value?.period || null);
    setFromDate(value?.from || null);
    setToDate(value?.to || null);
  }, [value]);

  return (
    <div
      ref={dropdownRef}
      className={`dropdown ${isOpen ? "dropdown-open" : ""} h-12 w-full`}>
      <div
        className={
          "flex h-full w-full cursor-pointer items-center justify-between rounded-[8px] border border-gray-300 py-2"
        }
        onClick={() => setIsOpen(true)}>
        <div
          className={classNames(
            "px-2 text-base",
            !activeValue && "text-gray-500",
          )}>
          {data?.find((item) => item.value === activeValue)?.label ||
            "Select..."}
        </div>
        <div
          className={
            "flex h-[30px] w-9 items-center justify-center border-l border-gray-300 text-base"
          }>
          <ChevronDown color={"rgba(0,0,0,0.2)"} size={28} />
        </div>
      </div>
      <div
        id={"dropdown"}
        className={
          "dropdown-content z-[1] mt-2 w-full rounded-[8px] border border-gray-300 bg-white py-1"
        }>
        {data.map((item) => (
          <div
            key={item.value}
            onClick={() => handleSelect(item.value)}
            className={
              "cursor-pointer px-3 py-2 text-sm font-semibold hover:bg-gray-100"
            }>
            {item.label}
          </div>
        ))}
        {activeValue === TimePeriod.Custom && (
          <div className={"px-3"}>
            <div>From</div>
            <div>
              <StyledDatePicker
                maxDate={toDate || moment().endOf("D").toDate()}
                value={fromDate || moment().startOf("D").toDate()}
                onChange={(date) =>
                  setFromDate(moment(date).startOf("D").toDate())
                }
                mountNode={"dropdown"}
              />
            </div>
            <div>To</div>
            <div>
              <StyledDatePicker
                minDate={fromDate || moment().startOf("D").toDate()}
                value={toDate || moment().endOf("D").toDate()}
                onChange={(date) => setToDate(moment(date).endOf("D").toDate())}
                mountNode={"dropdown"}
              />
            </div>
            <div className={"mt-2 grid grid-cols-2 gap-2"}>
              <div
                onClick={onApply}
                className={"bg-purple btn w-full  border-none normal-case"}>
                Apply
              </div>
              <div
                onClick={onCancel}
                className={"btn btn-outline w-full normal-case"}>
                Cancel
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
