import React, { FC, useEffect, useState } from "react";
import { Card } from "../../../../../../components/card/Card";
import Button from "../../../../../../components/button/Button";
import { HiPlus } from "react-icons/hi2";
import { LottieLoading } from "../../../../../../components/graphics/LottieLoading";
import NoItems from "../../../../../../components/NoItems";
import ContactNoteSvg from "../../../../../../components/svg/ContactNoteSvg";

import { useParams } from "react-router-dom";
import { FaCog } from "react-icons/fa";
import { HiOutlineMinusCircle } from "react-icons/hi";
import {
  useDeleteParticipantsMutation,
  useDeleteWorkNoteMutation,
  useGetParticipantsQuery,
  useGetWorkNotesQuery,
} from "../../../../../../slices/WorkNotesSlice";
import ConfirmModal from "../../../../../../components/modal/ConfirmModal";
import { IUser, IWorkNote } from "../../../../../../types/types";
import { WorkNoteView } from "./WorkNoteView";
import { WorkNoteModal } from "../../../../../work/modals/WorkNoteModal";

export const WorkNotes: FC = () => {
  const { workId } = useParams();

  const { data: workNotes, isLoading } = useGetWorkNotesQuery({
    workId: workId || "",
  });

  const { data: participants, isLoading: isLoadingParticipants } =
    useGetParticipantsQuery({
      workId: workId || "",
    });

  const [
    deleteParticipant,
    { isSuccess, isLoading: isLoadingDeleteParticipant },
  ] = useDeleteParticipantsMutation();
  const [deleteContactNote] = useDeleteWorkNoteMutation();

  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [currentNote, setCurrentNote] = useState<IWorkNote | null>(null);
  const [confirmDeleteParticipant, setConfirmDeleteParticipant] = useState<{
    id: string;
    userId: string;
    user: IUser;
  } | null>(null);

  const handleOpenAddModal = () => {
    setIsOpenAdd(true);
    setCurrentNote(null);
  };

  useEffect(() => {
    if (isSuccess) {
      setConfirmDeleteParticipant(null);
    }
  }, [isSuccess]);

  return (
    <>
      <Card extraClasses={"shadow-box overflow-visible"}>
        <div className={"h-full max-w-full pt-4"}>
          <div className={"mb-1 flex w-full justify-between px-5"}>
            <div className={"text-lg font-semibold"}>Recent Notes</div>
            <div className={"flex gap-2"}>
              <div className={"dropdown dropdown-end "}>
                <Button
                  label={""}
                  icon={<FaCog size={20} className={"text-purple"} />}
                  size={"custom"}
                  colorType={"ghost"}
                  extraClasses={"btn-sm px-2"}
                />
                <div
                  className={
                    "dropdown-content max-h-[200px] w-[250px] overflow-y-auto rounded-[5px] border border-gray-300 bg-white py-4 text-sm shadow-box"
                  }>
                  <div className={"flex flex-col gap-2"}>
                    <div className={"px-6"}>
                      <div className={"whitespace-nowrap text-sm font-bold"}>
                        Participants:
                      </div>
                      <div className={"text-xs text-gray-500"}>
                        Use @mention to add participants
                      </div>
                    </div>
                    {!!participants?.length && (
                      <div
                        className={
                          "border-purple flex flex-col gap-2 border-t-[1px] pt-2"
                        }>
                        {participants?.map((item) => (
                          <div
                            key={item?.id}
                            className={
                              "flex items-center justify-between gap-2 px-6"
                            }>
                            <div>
                              <div
                                className={
                                  "max-w-[200px] truncate text-sm font-semibold text-gray-600"
                                }>
                                {item?.user?.userProfile?.firstName}{" "}
                                {item?.user?.userProfile?.lastName}
                              </div>
                            </div>
                            <div>
                              <HiOutlineMinusCircle
                                onMouseDown={() => {
                                  setConfirmDeleteParticipant(item);
                                }}
                                className={"text-purple h-6 w-6 cursor-pointer"}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div
                onClick={handleOpenAddModal}
                className={
                  "flex cursor-pointer items-center text-base text-[#7B66F0]"
                }>
                Add note
              </div>
            </div>
          </div>
          <div className={"h-full"}>
            {isLoading ? (
              <div className={"p-4"}>
                <LottieLoading />
              </div>
            ) : !workNotes?.length ? (
              <div className={"h-[calc(100%-48px)] p-6"}>
                <NoItems
                  height={"100%"}
                  icon={<ContactNoteSvg />}
                  message={"There are no notes"}
                />
              </div>
            ) : (
              <div className={"max-h-[1100px] overflow-y-auto"}>
                {workNotes?.map((note) => (
                  <WorkNoteView
                    key={note?.id}
                    note={note}
                    setCurrentNote={setCurrentNote}
                    setIsOpenAdd={setIsOpenAdd}
                    deleteContactNote={deleteContactNote}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </Card>
      {confirmDeleteParticipant && (
        <ConfirmModal
          confirm={() =>
            deleteParticipant({
              workId: workId || "",
              id: confirmDeleteParticipant?.id || "",
            })
          }
          close={() => setConfirmDeleteParticipant(null)}
          title={""}
          message={
            <div className={"text-base text-gray-800"}>
              This user will no longer receive notifications regarding this
              conversation. Notifications can be resumed by using the @mention
              feature.
            </div>
          }
          isLoading={isLoadingDeleteParticipant}
          buttonsClasses={"flex-row-reverse gap-4"}
        />
      )}
      {isOpenAdd && (
        <WorkNoteModal
          workId={workId || ""}
          participants={participants || []}
          onClose={() => {
            setIsOpenAdd(false);
            setCurrentNote(null);
          }}
          isActive={isOpenAdd}
          defaultValues={currentNote || undefined}
        />
      )}
    </>
  );
};
