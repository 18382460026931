import React, { Dispatch, FC, SetStateAction } from "react";
import { timeFormatter } from "../../../constants/constants";
import moment from "moment/moment";
import Button from "../../../components/button/Button";
import { InvoiceItem } from "../../../services/BillingService";
import { InvoiceTime } from "../../time/InvoiceTime";

type Props = {
  billedWork: any;
  workName?: string;
  isActive: boolean;
  setCollapsedItems: Dispatch<SetStateAction<string[]>>;
  collapsedItems: string[];
  setViewWork: Dispatch<SetStateAction<boolean>>;
  totalRate: (value: string) => number;
  billedWorks?: InvoiceItem[];
  workType?: string;
  dueDate?: string;
  assignedUser?: string;
  qty?: number;
};
export const ViewWork: FC<Props> = ({
  billedWork,
  isActive,
  setCollapsedItems,
  collapsedItems,
  setViewWork,
  billedWorks,
  workName,
  workType,
  assignedUser,
  dueDate,
  qty,
  totalRate,
}) => {
  const work =
    billedWorks?.filter((i) => i.id === billedWork.id)?.[0] || billedWork;

  return (
    <div className={`modal ${isActive ? "modal-open" : "modal-close"}`}>
      <div className={"modal-box w-[80%] max-w-[90%]"}>
        <table className={"w-full"}>
          <thead className={"border-b-[1px] border-gray-300"}>
            <tr className={"py-[16px] pl-[16px] pr-[32px] last:pr-[16px]"}>
              <th
                className={
                  "py-[16px] pl-[16px] pr-[32px] text-left text-[14px] text-black last:pr-[16px]"
                }>
                Work
              </th>
              <th
                className={
                  "py-[16px] pl-[16px] pr-[32px] text-left text-[14px] text-black last:pr-[16px]"
                }>
                Work Type
              </th>
              <th
                className={
                  "py-[16px] pl-[16px] pr-[32px] text-left text-[14px] text-black last:pr-[16px]"
                }>
                Total Time
              </th>
              <th
                className={
                  "py-[16px] pl-[16px] pr-[32px] text-left text-[14px] text-black last:pr-[16px]"
                }>
                Date
              </th>
              <th
                className={
                  "py-[16px] pl-[16px] pr-[32px] text-left text-[14px] text-black last:pr-[16px]"
                }>
                Assignee
              </th>
              <th
                className={
                  "w-[140px] py-[16px] pl-[16px] pr-[32px] text-left text-[14px] text-black last:pr-[16px]"
                }>
                Notes
              </th>
              <th
                className={
                  "py-[16px] pl-[16px] pr-[32px] text-left text-[14px] text-black last:pr-[16px]"
                }>
                Total
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              key={work.id}
              className={
                "border-b-[1px] border-gray-300 last-of-type:border-none hover:bg-gray-100"
              }>
              <td className={"items-center px-[16px] py-[8px]"}>
                <div className={"flex items-center"}>
                  <div
                    className={
                      "max-w-[300px] items-center whitespace-normal text-sm text-black"
                    }>
                    {workName || work.work.name}
                  </div>
                </div>
              </td>
              <td
                className={
                  "px-[16px] py-[8px] align-top text-[14px] text-black"
                }>
                {workType || work.workType.name}
              </td>
              <td
                className={
                  "link-primary cursor-pointer items-center px-[16px] py-[8px] text-sm font-medium hover:underline"
                }>
                {qty
                  ? timeFormatter(qty * 3600)
                  : timeFormatter(work.quantity * 3600)}
              </td>
              <td
                className={
                  "px-[16px] py-[8px] align-top text-[14px] text-black"
                }>
                {moment(dueDate).format("YYYY-MM-DD") ||
                  moment(work.work.dueDate).format("YYYY-MM-DD")}
              </td>
              <td
                className={
                  "px-[16px] py-[8px] align-top text-[14px] text-black"
                }>
                {assignedUser ||
                  `${work.assignedUser.userProfile.firstName} ${work.assignedUser.userProfile.lastName}`}
              </td>
              <td
                className={"px-[16px] py-[8px] text-[14px] text-black"}
                onClick={() => {
                  setCollapsedItems((prev) =>
                    collapsedItems.includes(work?.id)
                      ? prev.filter((item) => item !== work?.id)
                      : [...prev, work?.id],
                  );
                }}>
                <div
                  className={`${
                    !collapsedItems.includes(work?.id)
                      ? "...  max-w-[100px] overflow-hidden text-ellipsis whitespace-nowrap sm:max-w-[100px] 2xl:max-w-[200px]"
                      : " max-w-[100px] whitespace-pre-line text-black"
                  }`}>
                  {work.notes}
                </div>
              </td>
              <td className={"px-[16px] py-[8px] text-[14px] text-black"}>
                {work.amount}$
              </td>
            </tr>
          </tbody>
        </table>

        <div className={"mt-3 flex w-full justify-end gap-4 px-5"}>
          <Button
            label={"Ok"}
            onClick={() => {
              setViewWork(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};
